import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, OverlayTrigger, Popover, Nav, Modal, Spinner } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { useTranslation } from 'react-i18next';
import { 
    mdiFaceAgent,
    mdiEmail,
    mdiPhone,
    mdiMenu,
    mdiFacebook,
    mdiYoutube,
    mdiInstagram
} from '@mdi/js';
import logo from '../../logo.complex.png';
import screenshot001 from './images/Portrait.001.png';
import visionImage from './images/vision.jpg';

import 'bootstrap/dist/css/bootstrap.min.css';
import './landing-page.css';
import LanguageToggleComponent from '../language';
import { DOCUMENT_WEB_DOCUMENTATION, DOCUMENT_TERMS_CONDITIONS } from "../../common/configurations/constants";
import * as docservice from '../../utilities/services/document-service'
import * as phraseservice from '../../utilities/services/phrase-service'


const ImagenGaleria = () => (
    <div className="d-flex justify-content-center align-items-center h-100">
        <div className="text-center w-100">
        <img 
            src={screenshot001} 
            alt="Screenshot of the app" 
            className="img-fluid mx-auto d-block"
            style={{
            maxWidth: '700px',
            width: '100%',
            height: 'auto',
            paddingLeft: '15px',
            paddingRight: '15px'
            }}
        />
        </div>
    </div>
);
  
const ContactUsModal = ({ contactUsModal, setContactUsModal }) => {
    const { t } = useTranslation();
    
    return (
        <Modal show={contactUsModal} onHide={() => setContactUsModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title as="div">
                    <div className="hstack gap-3">
                        <Icon path={mdiFaceAgent} size={1} />
                        {t('contactUsTitle')}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <p>{t('contactUsText')}</p>
                    <ol style={{ listStyle: 'none' }}>
                        <li style={{ paddingBottom: '20px' }}>
                            <strong><Icon path={mdiEmail} size={1} /></strong> 
                            {t('contactUsEmail')}
                        </li>
                        <li>
                            <strong><Icon path={mdiPhone} size={1} /></strong>
                            {t('contactUsPhone')}
                        </li>
                    </ol>
                    <p>{t('contactUsClosing')}</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setContactUsModal(false)}>
                    {t('close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
 
function LandingPage() {

    const navigate = useNavigate();
    const [contactUsModal, setContactUsModal] = useState(false);
    const [webAppDoc, setWebAppDoc] = useState('')
    const [termCondDoc, setTermCondDoc] = useState('')
    const [phrase, setPhrase] = useState('')
    const [loadingPhrase, setLoadingPhrase] = useState(true);
 
    const { t, i18n } = useTranslation();
     
    useEffect(()=> {

        // get content
        const load = async () => {
            try {
                const response1 = await docservice.get({ language: i18n.language, name: DOCUMENT_WEB_DOCUMENTATION });
                if (response1.success) setWebAppDoc(response1.data);

                const response2 = await docservice.get({ language: i18n.language, name: DOCUMENT_TERMS_CONDITIONS });
                if (response2.success) setTermCondDoc(response2.data);

                const response3 = await phraseservice.get({ language: i18n.language });
                if (response3.success) setPhrase(response3.data);
            } catch (error) {
                console.error('Error loading data:', error);
            } finally {
                setLoadingPhrase(false); // Detener loading al cargar la frase
            }
        }

        load()

    }, [i18n.language])

    const visionPopover = (
        <Popover id="vision-popover" className="custom-popover">
            <Popover.Body>
                <p>{t('visionText')}</p>
            </Popover.Body>
        </Popover>
    );
    
    const precioPopover = (
        <Popover id="precio-popover" className="custom-popover">
            <Popover.Body>
                <p>{t('priceText')}</p>
                <ul>
                    <li>{t('priceFeature')}</li>
                </ul>
            </Popover.Body>
        </Popover>
    );

    const scrollToSection = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };


    return (
        <div className="App">
            <ContactUsModal contactUsModal={contactUsModal} setContactUsModal={setContactUsModal} />

            <div className="watermark"></div>

            <nav className="navbar navbar-expand-lg custom-landing-nav">
                <div className="container-fluid">
                    <Link className="navbar-brand" to={`/landing`}>
                        <div className="hstack gap-2">
                            <img src={logo} height="25" alt="Logo" />
                        </div>
                    </Link>
                    <div className="ms-auto">
                        <LanguageToggleComponent t={t} i18n={i18n} />
                    </div>
                </div>
            </nav>

            <div className="buttons-menus">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="menu d-flex justify-content-start">
                            <Button 
                                variant="link" 
                                className="menu-link"
                                onClick={() => scrollToSection('landing-vision')}
                            >
                                {t('vision')}
                            </Button>
                            <Button 
                                variant="link" 
                                className="menu-link"
                                onClick={() => scrollToSection('landing-price')}
                            >
                                {t('price')}
                            </Button>
                            <Nav.Link as={Link} to={webAppDoc.url} target='_blank'>
                                <Button variant="link" className="menu-link">{t('documentation')}</Button>
                            </Nav.Link>
                            <Button variant="link" className="menu-link" onClick={() => setContactUsModal(true)}>
                                {t('contactUs')}
                            </Button>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 mt-3 mt-sm-0 d-flex justify-content-end align-items-center">
                        <button className="btn btn-custom-outline me-3" onClick={() => navigate("/login")}>{t('login')}</button>
                        <button className="btn btn-custom-primary" onClick={() => navigate("/signup")}>{t('signup')}</button>
                        <div className="d-flex gap-2 ms-3 ">
                            <a href="https://apps.apple.com/co/app/fgo360/id6737204398?l=en-GB" target="_blank" rel="noopener noreferrer">
                                <img 
                                    src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred_2x.png" 
                                    alt="Descargar en App Store" 
                                    className='image-application'
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
  
            <Container fluid className="">
                <div className='row py-5 landing-main-container'>
                    <div className="col-12 text-center">
                        <h2 className='mb-3' style={{ color: 'white' }}>
                            {t('mainTitle')} 
                        </h2>
                        <h5 className='mb-3' style={{ color: 'white' }}>
                            {t('subTitle')} 
                            
                        </h5>
                        {loadingPhrase ? (
                            <Spinner animation="border" variant="secondary" />
                        ) : (
                            <h5 className="phrase-highlight">
                                {phrase.phrase}
                            </h5>
                        )}
                       
                    </div>
                </div>
                <Row>
                    <Col lg={6} className="features px-4 mb-5">
                        <div className="feature">
                            <h3>{t('feature1')}</h3>
                        </div>
                        <div className="feature">
                            <h3>{t('feature2')}</h3>
                        </div>
                        <div className="feature">
                            <h3>{t('feature3')}</h3>
                        </div>
                        <div className="feature">
                            <h3>{t('feature4')}</h3>
                        </div>
                        <div className="feature">
                            <h3>{t('feature5')}</h3>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <ImagenGaleria />
                    </Col>
                </Row>
                

                <section 
                    id="landing-vision" 
                    className="section py-5 bg-light"
                    style={{ scrollMarginTop: '100px' }}
                >
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 mb-4">
                                <h2 className="section-title">{t('vision')}</h2>
                                <p className="vision-description">
                                    {t('visionText')}
                                </p>
                            </div>

                            <div className="col-lg-6 text-center">
                                <img 
                                    src={visionImage}
                                    alt="Nuestra visión" 
                                    className="vision-image"
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <section 
                    id="landing-price" 
                    className="section py-5"
                    style={{ scrollMarginTop: '100px' }}
                >
                    <div className="container">
                        <div className="text-center mb-5">
                            <h2 className="section-title">{t('price')}</h2>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-lg-6">
                                <div className="offer-box text-center p-5 mb-4 shadow-lg">
                                    <p className="lead mb-0">
                                        <span className="highlight">{t('offerTitle')}</span><br />
                                        <strong>{t('freeTrial')}</strong><br />
                                        {t('thenOnly')} <strong className="text-success">{t('annualPrice')}</strong><br />
                                        <span>{t('lessThan')}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
            </Container>
 
            <footer className="footer">
                <div className="footer-content">
                    <span>{t('footerRights')}</span>
                    <span className="separator">|</span>
                    <a href={termCondDoc.url} target="_blank" rel="noopener noreferrer">
                        {t('footerPolicy')}
                    </a>
                    <span className="separator">|</span>
                    <div className="social-media-links">
                        <a href="https://www.facebook.com/profile.php?id=61567121362684" target="_blank" rel="noopener noreferrer">
                            <Icon path={mdiFacebook} size={1} />
                        </a>
                        <a href="https://www.youtube.com/@FinanceGo360" target="_blank" rel="noopener noreferrer">
                            <Icon path={mdiYoutube} size={1} />
                        </a>
                        <a href="https://www.instagram.com/financego360/" target="_blank" rel="noopener noreferrer">
                            <Icon path={mdiInstagram} size={1} />
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default LandingPage;