import configTemplates from '../../common/configurations'
import * as c from "../../common/configurations/constants"
import * as tc from "../../common/configurations/text-constants"
import * as pickc from "../../common/configurations/picklist-constants"

const configurations = configTemplates.getConfigurations()
const elementTemplate = configTemplates.getObjectElement()

const configs = {

    ...configurations,

    actions: {

        ...configurations.actions,

        edit: {
            enable: true,
            visibilityFn: (e) => { return !e.is_standard },
            handler: function() { console.log('edit')}
        },
        clone: {
            enable: true,
            handler: function() { console.log('clone')}
        },
        add: {
            enable: true,
            handler: function() { console.log('add')}
        },
        addNew: {
            enable: true,
            handler: function() { console.log('add')}
        },
        remove: {
            enable: true,
            visibilityFn: (e) => { return !e.is_standard },
            handler: function() { console.log('remove')}
        },
        search: {
            enable: true,
            handler: function() { console.log('search')}
        },
        setup: {
            enable: true,
            visibilityFn: (e) => { return !e.is_standard },
            handler: function() { console.log('search')}
        },
    },

    layout: {

        ...configurations.layout,

        sections: [
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'name',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_GENERIC_NAME,
                        isRequired: true
                    }, 
                    {
                        ...elementTemplate,
                        isVisible: false
                    }
                ]
            },
            {
                type: 'data',
                ncols: 1,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'description',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_GENERIC_DESCRIPTION
                    }
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'number_columns',
                        label: tc.OBJECT_FIELD_DASHBOARD_NUMBER_OF_COLUMNS,
                        type: c.OBJECT_FIELD_DATA_TYPE_PICKLIST,
                        values: pickc.DASHBOARD_NUMBER_OF_COLUMNS,
                        isRequired: true,
                        isEditable: false,
                    },
                    {
                        ...elementTemplate,
                        isVisible: false
                    }
                ]
            },
            {
                type: 'separator_space'
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'created_date',
                        label: tc.OBJECT_FIELD_GENERIC_CREATED_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                        isEditable: false,
                        isCreatable: false,
                    },
                    {
                        ...elementTemplate,
                        name: 'modified_date',
                        label: tc.OBJECT_FIELD_GENERIC_LASTUPDATE_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                        isEditable: false,
                        isCreatable: false,
                    },
                ]
            },
            
        ],

        boostrapIcon: 'bi-file-earmark-ruled'
    },

    object: {
        name: 'dashboard',
        label: tc.OBJECT_DASHBOARD_LABEL,
        pluralLabel: tc.OBJECT_DASHBOARD_PLURAL_LABEL,
        basePath: 'dashboards',
        sliceName: 'dashboard',
        menuLabel: tc.NAV_MENU_DATA_DASHBOARD_LABEL,
        printable: true
    },

    list: {
        items: [
            {
                name: 'name',
                type: 'text',
                label: tc.OBJECT_FIELD_GENERIC_NAME,
                isLinked: true
            },
            {
                name: 'description',
                type: 'text',
                label: tc.OBJECT_FIELD_GENERIC_DESCRIPTION,
                compactViewLeftDetail: true,
                isLinked: false
            },
            {
                name: 'created_date',
                type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                label: tc.OBJECT_FIELD_GENERIC_CREATED_DATE,
                isLinked: false
            }
        ]
    } 
}
 
export default configs