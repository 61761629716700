import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux' 
import { Link } from 'react-router-dom'
import _ from "lodash"
import { useTranslation } from 'react-i18next';

import * as tc from '../../../configurations/text-constants'

import * as service from '../../../../utilities/services/account-service'

import OutputField from "../../../layouts/fields/output-field"
  
const TreeItemDetailPageChildren = ({element, configs, user}) => {
    
    const { t } = useTranslation();

    const [children, setChildren] = useState([])

    const dispatch = useDispatch()
 
    const getTreeHeaders = (configs) => {

        return configs.treelist.items.map((c, i) => {
            return (
                <th key={i} className={`tree-list-column ${c.isCompactVisible? '': 'd-none d-md-table-cell'}`}> 
                    {c.label}
                </th>
            )
        })
    }

    // header
    const Header = ({configs}) => {
 
        return (
            <thead>
                <tr className="text-uppercase">
                    <th>
                        {t(tc.OBJECT_FIELD_GENERIC_NAME)}
                    </th>
                    {getTreeHeaders(configs)}
                </tr>
            </thead>
        )
    }

    const getTreeColumns = (configs, element) => {

        return configs.treelist.items.map((c, i) => {
            return (
                <td key={i}  className={`tree-list-column ${c.isCompactVisible? '': 'd-none d-md-table-cell'}`}> 
                    <OutputField vConfig={{type: c.type}} value={element[c.name]}/>
                </td>
            )
        })
    }

    // body
    const Body = ({configs}) => {
   
        return (
            <tbody>
                {  
                    children.map(c => {
                        return (
                            <tr key={c.id}>
                                <td>
                                    <Link to={`/${configs.object.basePath}/${c.id}/view`}>
                                        {c.name}
                                    </Link>
                                </td>
                                {getTreeColumns(configs, c)}
                            </tr>
                        )
                    })
                }
                <tr>
                    
                </tr>
            </tbody>
        )
    }

    useEffect(()=> {

        const load = async () => {
            const response = await service.getChildren({payload: {id: element.id}, user}, dispatch)
            if(response.success)
                setChildren(response.data)
        }

        load()
        
    }, [])

    return (
        <table className="table">
           <Header configs={configs}/>
           <Body configs={configs}/>
        </table>
       
    )
}

export default TreeItemDetailPageChildren