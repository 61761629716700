import React, { Fragment, useEffect, useState, useRef } from "react";
import { Nav, Navbar, NavDropdown, Accordion, Modal } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Icon from '@mdi/react';
import { 
    mdiFaceAgent,
    mdiEmail,
    mdiPhone,
    mdiMenu,
    mdiAccount
} from '@mdi/js';
import { useTranslation } from 'react-i18next'; // Import i18n hook

import configs from '../../../utilities/configuration-container'
import * as tc from '../../../common/configurations/text-constants'
import * as uservice from '../../../utilities/services/user-service'
import * as docservice from '../../../utilities/services/document-service'
 
import './authenticated-container.css'
import logo from '../../../logo.complex.png'
import MaterialIcon from "../../../common/icons/material-icon";
import { DOCUMENT_WEB_DOCUMENTATION } from "../../../common/configurations/constants";
 
const ContactUsModal = ({contactUsModal, setContactUsModal}) => {
   
    const { t } = useTranslation(); // Hook for translations

    return (
        <Modal show={contactUsModal} onHide={() => setContactUsModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title as="div">
                    <div className="hstack gap-3">
                        <Icon path={mdiFaceAgent} size={1} />
                        {t('contactUsTitle')}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <p>{t('contactUsText')}</p>
                    <ol style={{listStyle: 'none'}}>
                        <li style={{paddingBottom: '20px'}}>
                            <strong><Icon path={mdiEmail} size={1} /></strong> {t('contactUsEmail')}
                        </li>
                        <li>
                            <strong><Icon path={mdiPhone} size={1} /></strong> {t('contactUsPhone')}
                        </li>
                    </ol>
                    <p>{t('contactUsClosing')}</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-custom-secondary btn-sm" onClick={() => setContactUsModal(false)}>
                    {t('BUTTON_CLOSE_LABEL')}
                </button>
            </Modal.Footer>
        </Modal>
    )
}

const ConfigurationNavDropdownBody = ({user, navigate, dispatch, select}) => {
 
    const { t, i18n } = useTranslation();

    const [webDoc, setWebDoc] = useState('')
    const logout = async e => {
        await uservice.logout(user, dispatch); 
        navigate('/')
    }

    useEffect(()=> {
        // get web document
        const loadDocument = async () => {
            const response = await docservice.get(i18n.language, DOCUMENT_WEB_DOCUMENTATION)
            if(response.success)
                setWebDoc(response.data)
        }
        loadDocument()

    })

    return (
        <div>
            <NavDropdown.Item  to={`#`} onClick={() => select('contact')}>{t('contactUsTitle')}</NavDropdown.Item>
            <NavDropdown.Item as={Link} to={`${webDoc.url}`} target="_blank">{t('documentation')}</NavDropdown.Item>
            <NavDropdown.Divider></NavDropdown.Divider>
            <NavDropdown.Item as={Link} to={`user-profiles/${user.profile_id}`}>{t('profile')}</NavDropdown.Item>
            {
                !user.is_admin &&
                <NavDropdown.Item as={Link} to={`user-automated-emails`}>{t('emails')}</NavDropdown.Item>
            }
            {
                !user.is_admin &&
                <NavDropdown.Item as={Link} to={`payment`} disabled={user.was_paid}>{t('payment')}</NavDropdown.Item>
            }
            <NavDropdown.Divider></NavDropdown.Divider>
            <NavDropdown.Item onClick={logout}>{t('logout')}</NavDropdown.Item>
        </div>
       
    )
}

const ConfigurationNavDropdownCompact = ({user, ekey, header, navigate, dispatch, select}) => {
  
    const { t } = useTranslation();

    const logout = async e => {
        await uservice.logout(user, dispatch); 
        navigate('/')
    }

    return (
        <Accordion.Item eventKey={ekey}>
            <Accordion.Header >{`${t(header)}:  ${user.username}`}</Accordion.Header>
            <Accordion.Body>
                <p className="" onClick={() => select('contact')}>
                    <Link className="navbar-brand"  to={`#`} style={{display: 'flex', alignItems: 'center'}} > 
                        <span className="" >{t('contactUsTitle')}</span>
                    </Link>
                </p>
                <p className="" onClick={select}>
                    <Link className="navbar-brand"  to={`${process.env.REACT_APP_DOCUMENT_URL}`} target="_blank" style={{display: 'flex', alignItems: 'center'}} > 
                        <span className="" >{t('documentation')}</span>
                    </Link>
                </p>
                <p className="" onClick={select}>
                    <Link className="navbar-brand" to={`user-profiles/${user.profile_id}`} style={{display: 'flex', alignItems: 'center'}} > 
                        <span className="" >{t('profile')}</span>
                    </Link>
                </p>
                {
                    !user.is_admin &&
                    <Fragment>
                        <p className="" onClick={select}>
                            <Link className="navbar-brand" to={`user-automated-emails`} style={{display: 'flex', alignItems: 'center'}} > 
                                <span className="" >{t('emails')}</span>
                            </Link>
                        </p>
                        <p className={`${user.was_paid ? "disabled-link" : ""}`} onClick={select}>
                            <Link className="navbar-brand" to={`payment`} style={{display: 'flex', alignItems: 'center'}} > 
                                <span className="" >{t('payment')}</span>
                            </Link>
                        </p>
                    </Fragment>
                }
                <p className="" onClick={logout} >
                    <Link className="navbar-brand"  to={`#`} style={{display: 'flex', alignItems: 'center'}} > 
                        <span className="" >{t('logout')}</span>
                    </Link>
                </p>
            </Accordion.Body>
        </Accordion.Item> 
    )
}

const ConfigurationNavDropdown = ({ user, navigate, dispatch, select }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);

    // Alternar el estado del dropdown al hacer clic
    const handleDropdownClick = () => setShowDropdown((prev) => !prev);

    // Cerrar el dropdown al hacer clic fuera
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    // Agregar y eliminar event listeners para clics externos
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <NavDropdown
            ref={dropdownRef}
            title={<Icon path={mdiAccount} size={1} />}
            className="config-menu-item-container"
            show={showDropdown}
            onClick={handleDropdownClick} // Solo abrir/cerrar con clic
        >
            <div style={{ padding: 0, backgroundColor: 'transparent' }}>
                <ConfigurationNavDropdownBody
                    user={user}
                    navigate={navigate}
                    dispatch={dispatch}
                    select={select}
                />
            </div>
        </NavDropdown>
    );
};
 
const CustomBootstrapNavDropdown = ({ header, configs, select }) => {

    const { t } = useTranslation();
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);

    // Cerrar el dropdown al hacer clic fuera
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    // Añadir y limpiar event listeners para clics externos
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Alternar el dropdown al hacer clic en el encabezado
    const toggleDropdown = () => {
        setShowDropdown((prev) => !prev);
    };

    // Cerrar el dropdown y ejecutar la función de selección al hacer clic en un item
    const handleSelect = (option) => {
        setShowDropdown(false); // Cierra el dropdown
        select(option);
    };

    return (
        <NavDropdown
            ref={dropdownRef}
            title={t(header)}
            className="nav-menu-item-container large-nav-link"
            show={showDropdown}
            onClick={toggleDropdown}
        >
            {configs.map((c, index) =>
                c ? (
                    <NavDropdown.Item
                        as="div"
                        key={index}
                        style={{backgroundColor: "transparent"}}
                    >
                        <Link
                            className="navbar-brand"
                            to={c.object.basePath}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '0px',
                                textDecoration: 'none',
                            }}
                        >
                            <div
                                className="d-none d-md-block"
                                style={{ marginRight: '10px' }}
                                onClick={() => handleSelect(c)}
                            >
                                <MaterialIcon obj={c.object.name} />
                            </div>
                            <span className="menu-item-submenu" style={{ fontStyle: 'normal' }}>
                                {t(c.object.pluralLabel)}
                            </span>
                        </Link>
                    </NavDropdown.Item>
                ) : (
                    <NavDropdown.Divider key={`divider-${index}`} />
                )
            )}
        </NavDropdown>
    );
};

const CustomBootstrapNavDropdownCompact = ({header, configs, select, ekey}) => {
 
    const { t } = useTranslation();

    return (
        <Accordion.Item eventKey={ekey}>
            <Accordion.Header >{`${t(header)}`}</Accordion.Header>
            <Accordion.Body>
                {
                    configs.map((c, index) => {

                        if (!c) {
                            return (
                                <hr key={`divider-${index}`} className="my-2" />
                            );
                        }

                        return (
                            <div className="mb-2" key={index} onClick={select} >
                                <Link className="navbar-brand" to={`${c.object.basePath}`} style={{display: 'flex', alignItems: 'center', padding: '0px'}} > 
                                    <div className=""  style={{marginRight: '10px'}}>
                                        <MaterialIcon obj={c.object.name} />
                                    </div>
                                    <span  className="" >{t(c.object.pluralLabel)}</span>
                                </Link>
                            </div>
                        )
                    })
                }
            </Accordion.Body>
        </Accordion.Item>
    )
}
 
const AuthenticatedContainerHeader = ({user, sideMenus}) => {
     
    const [expanded, setExpanded] = useState(false);
    const [contactUsModal, setContactUsModal] = useState(false);
  
    const handleToggle = () => setExpanded(!expanded);
    const handleClose = () => setExpanded(false);

    const handleSelect = (item) => {
        setExpanded(false)

        if(item=='contact')
            setContactUsModal(true)
    }

    const navigate = useNavigate()

    const dispatch = useDispatch()
   
    return ( 
        <Navbar bg="light" expand="lg" className="custom-top-header fixed-top" expanded={expanded}>

            <div className="container-fluid d-flex align-items-center">
 
                <Navbar.Brand as={Link} to="home/" className="me-2 d-flex align-items-center">
                    <div className="hstack gap-2">
                        <img src={logo} height="25" alt="logo" />
                    </div>
                </Navbar.Brand>
  
                {/** Menu for large devices */}
                <div className="d-none d-lg-flex align-items-center flex-grow-1">
                    <Nav className="me-auto">
                    
                        {
                            user.is_admin &&
                            <CustomBootstrapNavDropdown header={tc.NAV_MENU_AUTOMATION_TRANSACTION_EMAIL_LABEL} configs={[
                                configs.transactionEmail,
                                configs.transactionSMS,
                                null, 
                                configs.expenseEmail, 
                                configs.incomeEmail,
                                null,
                                configs.assetEmail,
                                configs.liabilityPaymentEmail
                            ]}
                            select={handleSelect}/>
                        }

                        {
                            user.is_admin &&
                            <CustomBootstrapNavDropdown header={tc.NAV_SECTION_DATA_SYSTEM_LABEL} configs={[
                                configs.reportStandard,
                                configs.chartStandard, 
                                configs.dashboardStandard, 
                                configs.reportType, 
                            ]} select={handleSelect}/>
                        } 
                            
                        {
                            !user.is_admin &&
                            <CustomBootstrapNavDropdown header={tc.NAV_MENU_EXPENSE_LABEL} configs={[
                                configs.accountExpense, 
                                configs.expense,
                                configs.expenseTemplate,
                                configs.vendor, 
                                configs.expenseBudget, 
                            ]} select={handleSelect}/>
                        }

                        {
                            !user.is_admin &&
                            <CustomBootstrapNavDropdown header={tc.NAV_MENU_INCOME_LABEL} configs={[
                                configs.accountIncome,
                                configs.income,
                                configs.incomeTemplate,
                                configs.buyer,
                            ]} select={handleSelect}/>
                        }

                        {
                            !user.is_admin &&
                            <CustomBootstrapNavDropdown header={tc.NAV_MENU_ASSET_LIABILITY_LABEL} configs={[
                                configs.accountAsset,
                                configs.accountLiability,
                                configs.asset, 
                                configs.assetTemplate,
                                configs.liabilityPayment,
                            ]} select={handleSelect}/>
                        }

                        {
                            !user.is_admin &&
                            <CustomBootstrapNavDropdown header={tc.NAV_SECTION_PAYMENT_METHODS_LABEL} configs={[
                                configs.paymentMethodBank, 
                                configs.paymentMethodCard, 
                                configs.paymentMethodCash,
                                configs.paymentMethodWallet, 
                            ]} select={handleSelect}/>
                        }

                        {
                            <CustomBootstrapNavDropdown header={tc.NAV_SECTION_DATA_LABEL} configs={[
                                configs.report,
                                configs.chart, 
                                configs.dashboard,
                            ]} select={handleSelect}/>
                        }

                        
                    </Nav>
                    <div className="ms-auto d-flex align-items-center">
                        <div className="hstack gap-2">
                            <span>{user.username}</span>
                            <ConfigurationNavDropdown user={user} dispatch={dispatch} navigate={navigate} select={handleSelect}/>
                        </div>
                        
                    </div>
                </div>
                
                <Navbar.Toggle style={{ padding: 3, margin: 3 }} onClick={handleToggle} className="ms-auto">
                    <Icon path={mdiMenu} size={1} color="black" style={{ width: '15px', height: '15px' }} />
                </Navbar.Toggle>

            </div> 

            {/** Menu for small devices */}
            <Modal show={expanded} onHide={handleClose} dialogClassName="modal-top" fullscreen={true}>
                <Modal.Header closeButton>
                    
                </Modal.Header>
                <Modal.Body>
 
                    <Accordion >
 
                        {user.is_admin && (
                            <CustomBootstrapNavDropdownCompact header={tc.NAV_MENU_AUTOMATION_TRANSACTION_EMAIL_LABEL} configs={[
                                configs.transactionEmail, 
                                null,
                                configs.expenseEmail, 
                                configs.incomeEmail,
                                null,
                                configs.assetEmail, 
                                configs.liabilityPaymentEmail
                            ]}
                            ekey={0}
                            select={handleSelect}></CustomBootstrapNavDropdownCompact>
                        )}

                        {user.is_admin && (
                            <CustomBootstrapNavDropdownCompact header={tc.NAV_SECTION_DATA_SYSTEM_LABEL} configs={[
                                configs.reportStandard,
                                configs.chartStandard,
                                configs.dashboardStandard, 
                                null,
                                configs.reportType,
                            ]}
                            ekey={1}
                            select={handleSelect}></CustomBootstrapNavDropdownCompact>
                        )}
 
                        {!user.is_admin && (
                            <CustomBootstrapNavDropdownCompact header={tc.NAV_MENU_EXPENSE_LABEL} configs={[
                                configs.accountExpense, 
                                configs.expense,
                                configs.expenseTemplate,
                                configs.vendor, 
                                configs.expenseBudget, 
                            ]}
                            ekey={3}
                            select={handleSelect}></CustomBootstrapNavDropdownCompact>
                        )}

                        {!user.is_admin && (
                            <CustomBootstrapNavDropdownCompact header={tc.NAV_MENU_INCOME_LABEL} configs={[
                                configs.accountIncome,
                                configs.income,
                                configs.incomeTemplate,
                                configs.buyer,
                            ]}
                            ekey={4}
                            select={handleSelect}></CustomBootstrapNavDropdownCompact>
                        )}

                        {!user.is_admin && (
                            <CustomBootstrapNavDropdownCompact header={tc.NAV_MENU_ASSET_LIABILITY_LABEL} configs={[
                                configs.accountAsset,
                                configs.accountLiability,
                                configs.asset, 
                                configs.assetTemplate,
                            ]}
                            ekey={5}
                            select={handleSelect}></CustomBootstrapNavDropdownCompact>
                        )}
 

                        {!user.is_admin && (
                            <CustomBootstrapNavDropdownCompact header={tc.NAV_SECTION_PAYMENT_METHODS_LABEL} configs={[
                                configs.paymentMethodBank, 
                                configs.paymentMethodCard, 
                                configs.paymentMethodCash, 
                                configs.paymentMethodWallet, 
                            ]}
                            ekey={8}
                            select={handleSelect}></CustomBootstrapNavDropdownCompact>
                        )}
         
                        <CustomBootstrapNavDropdownCompact header={tc.NAV_SECTION_DATA_LABEL} 
                            configs={[
                                configs.report,
                                configs.chart,
                                configs.dashboard,
                            ]}
                            ekey={2}
                            select={handleSelect}>
                        </CustomBootstrapNavDropdownCompact>

                        <ConfigurationNavDropdownCompact header={`cuentaHeader`}
                            user={user}
                            ekey={9}
                            select={handleSelect}
                            dispatch={dispatch}
                            navigate={navigate}></ConfigurationNavDropdownCompact>

                    </Accordion>
                </Modal.Body>
            </Modal> 

            <ContactUsModal contactUsModal={contactUsModal} setContactUsModal={setContactUsModal} />
            
        </Navbar>
    )
}
 
export default AuthenticatedContainerHeader